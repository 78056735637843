import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/dollar.png'
import _imports_1 from '@/assets/images/logoquilt.svg'


const _hoisted_1 = { class: "header pb-[75px] md:pb-[200px]" }
const _hoisted_2 = { class: "relative z-20 w-full pt-8 md:pt-12 justify-center flex flex-col items-center" }
const _hoisted_3 = { class: "mt-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"w-11/12 text-center text-white mt-10\" data-v-c15d083c><h1 class=\"font-ammocorps text-3xl md:text-6xl lg:text-[5rem] leading-10 md:leading-none text-center\" data-v-c15d083c><span class=\"inline md:block\" data-v-c15d083c>Introducing The</span> Stock Market for Ammo </h1><h2 class=\"text-4xl md:text-4xl mt-4 md:mt-4 font-extralight opacity-80 pb-8\" data-v-c15d083c><span class=\"block md:inline\" data-v-c15d083c>Your Ultimate Hedge </span>Against Uncertainty</h2></div>", 1)),
      _createElementVNode("div", {
        class: "playbutton-wrapper flex flex-col items-center w-11/12 md:w-10/12 text-center text-white md:mt-2 lg:mt-4",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openHowItWorksVideo && _ctx.openHowItWorksVideo(...args)))
      }, _cache[2] || (_cache[2] = [
        _createStaticVNode("<h1 class=\"text-sm md:text-lg opacity-40 mb-8\" data-v-c15d083c>WATCH THE VIDEO</h1><div class=\"w-full flex justify-center items-center\" data-v-c15d083c><div class=\"h-12 md:h-20 w-4/12 flex flex-col justify-between items-end\" data-v-c15d083c><span class=\"block h-px w-full bg-white playbutton-stripes-left\" data-v-c15d083c></span><span class=\"block h-px w-9/12 bg-white mr-4 playbutton-stripes-left\" data-v-c15d083c></span><span class=\"block h-px w-full bg-white playbutton-stripes-left\" data-v-c15d083c></span></div><div class=\"h-16 md:h-24 w-16 md:w-24 playbutton shadow-xl\" data-v-c15d083c><div class=\"h-full w-full playbutton-inner\" data-v-c15d083c></div></div><div class=\"h-12 md:h-20 w-4/12 flex flex-col justify-between\" data-v-c15d083c><span class=\"block h-px w-full bg-white playbutton-stripes-right\" data-v-c15d083c></span><span class=\"block h-px w-9/12 bg-white ml-4 playbutton-stripes-right\" data-v-c15d083c></span><span class=\"block h-px w-full bg-white playbutton-stripes-right\" data-v-c15d083c></span></div></div>", 2)
      ])),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
          class: _normalizeClass([[_ctx.isDarkTheme ? 'text-gray-400' : 'text-gray-200'], "px-10 py-2 text-2xl font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded"])
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "" }, "Join the Platform", -1)
        ]), 2)
      ])
    ]),
    _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"animated-bg\" data-v-c15d083c><img style=\"transform:translateY(150px);left:10%;animation-delay:0.5s;\" class=\"animated-dollar\" src=\"" + _imports_0 + "\" data-v-c15d083c><img style=\"transform:translateY(90px);left:30%;animation-delay:3s;\" class=\"animated-dollar\" src=\"" + _imports_0 + "\" data-v-c15d083c><img style=\"transform:translateY(20px);left:70%;animation-delay:1.2s;\" class=\"animated-dollar\" src=\"" + _imports_0 + "\" data-v-c15d083c><img style=\"transform:translateY(190px);left:90%;animation-delay:2s;\" class=\"animated-dollar\" src=\"" + _imports_0 + "\" data-v-c15d083c></div><div class=\"bg-image absolute z-10 px-2\" data-v-c15d083c><img style=\"height:intrinsic;\" src=\"" + _imports_1 + "\" data-v-c15d083c></div>", 2))
  ]))
}