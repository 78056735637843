import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-[#3d4957] py-6 px-12 text-left md:text-center grid md:grid-cols-3 gap-4 text-[#c4c8cc]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", null, "1504 Alcovy Trestle Rd, Social Circle, GA 30025", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, [
      _createTextVNode(" Ryan Millsap "),
      _createElementVNode("a", {
        class: "hover:underline",
        href: "mailto:ryan@ammoexchange.com"
      }, "(ryan@ammoexchange.com)"),
      _createTextVNode(", Garrett Gravesen "),
      _createElementVNode("a", {
        class: "hover:underline",
        href: "mailto:garrett@ammoexchange.com"
      }, "(garrett@ammoexchange.com)")
    ], -1)),
    _createElementVNode("p", null, [
      _createVNode(_component_router_link, {
        class: "hover:underline",
        to: "/legal"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("User Agreement")
        ])),
        _: 1
      })
    ])
  ]))
}