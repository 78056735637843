import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_2 = { class: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6" }
const _hoisted_3 = {
  key: 0,
  class: "free-box-of-ammo p-4 pl-5"
}
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex-shrink-0" }
const _hoisted_6 = { class: "text-sm font-medium text-gray-800" }
const _hoisted_7 = { class: "countdown font-ammocorps text-xl" }
const _hoisted_8 = { class: "p-4" }
const _hoisted_9 = { class: "font-ammocorps text-center pt-3 text-4xl" }
const _hoisted_10 = { class: "font-light" }
const _hoisted_11 = {
  key: 0,
  class: "form-error rounded-md bg-red-50 p-4 mb-3 mt-5"
}
const _hoisted_12 = { class: "flex" }
const _hoisted_13 = { class: "flex-shrink-0" }
const _hoisted_14 = { class: "ml-3" }
const _hoisted_15 = { class: "text-sm font-medium text-red-800" }
const _hoisted_16 = { class: "mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" }
const _hoisted_17 = { class: "sm:col-span-3" }
const _hoisted_18 = { class: "mt-1" }
const _hoisted_19 = { class: "sm:col-span-3" }
const _hoisted_20 = { class: "mt-1" }
const _hoisted_21 = { class: "mt-1" }
const _hoisted_22 = { class: "mt-1" }
const _hoisted_23 = { class: "font-light text-sm pt-3" }
const _hoisted_24 = { class: "" }
const _hoisted_25 = {
  type: "submit",
  class: "w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogOverlay = _resolveComponent("DialogOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_GiftIcon = _resolveComponent("GiftIcon")!
  const _component_ExclamationTriangleIcon = _resolveComponent("ExclamationTriangleIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.isOpen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "fixed z-10 inset-0 overflow-y-auto",
        onClose: _ctx.closeOverlay
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DialogOverlay, { class: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" })
              ]),
              _: 1
            }),
            _cache[16] || (_cache[16] = _createElementVNode("span", {
              class: "hidden sm:inline-block sm:align-middle sm:h-screen",
              "aria-hidden": "true"
            }, "​", -1)),
            _createVNode(_component_TransitionChild, {
              as: "template",
              enter: "ease-out duration-300",
              "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
              "enter-to": "opacity-100 translate-y-0 sm:scale-100",
              leave: "ease-in duration-200",
              "leave-from": "opacity-100 translate-y-0 sm:scale-100",
              "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", null, [
                    (_ctx.invite)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_GiftIcon, {
                                class: "h-8 w-8 mr-4 relative top-1",
                                "aria-hidden": "true"
                              })
                            ]),
                            _createElementVNode("p", _hoisted_6, [
                              _cache[6] || (_cache[6] = _createElementVNode("div", null, "Your founding trader invite expires in", -1)),
                              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.inviteCountdown.hours) + " hours, " + _toDisplayString(_ctx.inviteCountdown.minutes) + " minutes, " + _toDisplayString(_ctx.inviteCountdown.seconds) + " seconds", 1)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.invite ? 'ACTIVATE' : 'CREATE') + " YOUR TRADING ACCOUNT", 1),
                      _createElementVNode("p", _hoisted_10, [
                        _cache[7] || (_cache[7] = _createTextVNode(" Fill out the form below to create your account, or ")),
                        _createElementVNode("a", {
                          class: "cursor-pointer text-indigo-600",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openLogin && _ctx.openLogin(...args)))
                        }, "click to login"),
                        _cache[8] || (_cache[8] = _createTextVNode(" if you're already a member. "))
                      ]),
                      (_ctx.formError)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", _hoisted_13, [
                                _createVNode(_component_ExclamationTriangleIcon, {
                                  class: "h-5 w-5 text-red-400",
                                  "aria-hidden": "true"
                                })
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.formError), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("form", {
                        class: "space-y-6 w-5/5 mx-auto",
                        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createAccount && _ctx.createAccount(...args)), ["prevent"]))
                      }, [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", _hoisted_17, [
                            _cache[9] || (_cache[9] = _createElementVNode("label", {
                              for: "firstName",
                              class: "block text-sm font-medium text-gray-400"
                            }, " First Name ", -1)),
                            _createElementVNode("div", _hoisted_18, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.firstName) = $event)),
                                id: "firstName",
                                name: "firstName",
                                type: "text",
                                autocomplete: "given-name",
                                required: "",
                                class: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              }, null, 512), [
                                [_vModelText, _ctx.model.firstName]
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _cache[10] || (_cache[10] = _createElementVNode("label", {
                              for: "lastName",
                              class: "block text-sm font-medium text-gray-400"
                            }, " Last Name ", -1)),
                            _createElementVNode("div", _hoisted_20, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.lastName) = $event)),
                                id: "lastName",
                                name: "lastName",
                                type: "text",
                                autocomplete: "family-name",
                                required: "",
                                class: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              }, null, 512), [
                                [_vModelText, _ctx.model.lastName]
                              ])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _cache[11] || (_cache[11] = _createElementVNode("label", {
                            for: "email",
                            class: "block text-sm font-medium text-gray-400"
                          }, " Email address ", -1)),
                          _createElementVNode("div", _hoisted_21, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.email) = $event)),
                              id: "email",
                              name: "email",
                              type: "email",
                              autocomplete: "email",
                              required: "",
                              class: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            }, null, 512), [
                              [_vModelText, _ctx.model.email]
                            ])
                          ])
                        ]),
                        _createElementVNode("div", null, [
                          _cache[12] || (_cache[12] = _createElementVNode("label", {
                            for: "password",
                            class: "block text-sm font-medium text-gray-400"
                          }, " Password ", -1)),
                          _createElementVNode("div", _hoisted_22, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.password) = $event)),
                              id: "password",
                              name: "password",
                              type: "password",
                              autocomplete: "current-password",
                              required: "",
                              class: "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            }, null, 512), [
                              [_vModelText, _ctx.model.password]
                            ])
                          ])
                        ]),
                        _createElementVNode("p", _hoisted_23, [
                          _cache[14] || (_cache[14] = _createTextVNode(" By clicking \"Create Account\", you confirm that you are at least 18 years of age and have read the ")),
                          _createVNode(_component_router_link, {
                            onClick: _ctx.closeOverlay,
                            class: "text-indigo-600",
                            to: "/legal"
                          }, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode("Terms and Conditions")
                            ])),
                            _: 1
                          }, 8, ["onClick"]),
                          _cache[15] || (_cache[15] = _createTextVNode(". "))
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("button", _hoisted_25, _toDisplayString(_ctx.isSubmitting ? 'Creating Account...' : 'Create Account'), 1)
                        ])
                      ], 32)
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["onClose"])
    ]),
    _: 1
  }, 8, ["show"]))
}