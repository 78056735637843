import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "NAV-FOR-PUBLIC-COMPONENT" }
const _hoisted_2 = { class: "flex mx-auto px-4 sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "flex-shrink-0" }
const _hoisted_4 = { class: "ml-3" }
const _hoisted_5 = {
  key: 0,
  class: "text-xs md:text-sm font-medium text-gray-800"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  class: "hidden md:inline relative countdown font-ammocorps text-lg",
  style: {"top":"1px"}
}
const _hoisted_9 = { class: "block md:hidden countdown font-ammocorps text-sm" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 0,
  class: "ml-auto pl-3"
}
const _hoisted_13 = { class: "-my-1" }
const _hoisted_14 = {
  key: 1,
  class: "ml-auto"
}
const _hoisted_15 = { class: "-my-1" }
const _hoisted_16 = { class: "mx-auto px-4 sm:px-6 lg:px-8" }
const _hoisted_17 = { class: "flex items-center justify-between relative h-16" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "flex flex-row items-center justify-end" }
const _hoisted_20 = { class: "ml-4 flex items-center md:ml-6" }
const _hoisted_21 = { class: "md:block" }
const _hoisted_22 = { class: "ml-10" }
const _hoisted_23 = {
  key: 0,
  class: "pl-5 flex space-x-3"
}
const _hoisted_24 = ["href"]
const _hoisted_25 = {
  key: 1,
  class: "sm:pl-5 pl-14 flex space-x-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GiftIcon = _resolveComponent("GiftIcon")!
  const _component_InlineSvg = _resolveComponent("InlineSvg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ChevronDoubleRightIcon = _resolveComponent("ChevronDoubleRightIcon")!
  const _component_Disclosure = _resolveComponent("Disclosure")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.invite)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "FREE-BOX-OF-AMMO pt-4 pb-3",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openCardDetails && _ctx.openCardDetails(...args)))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_GiftIcon, {
                class: "h-6 w-6",
                "aria-hidden": "true"
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.invite.isValid)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                    (_ctx.invite.firstName)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.invite.firstName) + ", your ", 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_7, "Your ")),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "hidden md:inline" }, "founding trader ", -1)),
                    _cache[6] || (_cache[6] = _createTextVNode("invite expires in ")),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.inviteCountdown?.hours) + " hours, " + _toDisplayString(_ctx.inviteCountdown?.minutes) + " minutes, " + _toDisplayString(_ctx.inviteCountdown?.seconds) + " seconds", 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.inviteCountdown?.hours) + " hrs, " + _toDisplayString(_ctx.inviteCountdown?.minutes) + " mins, " + _toDisplayString(_ctx.inviteCountdown?.seconds) + " secs", 1)
                  ]))
                : (_ctx.invite.hasBeenRedeemed)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, " Your Founding Trader link has already been used. "))
                  : (_openBlock(), _createElementBlock("p", _hoisted_11, " Your Founding Trader link has expired. "))
            ]),
            (_ctx.invite.isValid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("button", {
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
                      type: "button",
                      class: _normalizeClass([[_ctx.showFoundingTraderButton ? 'inline-flex' : ' hidden'], "free-ammo-button relative mt-1 px-3 py-1 text-sm font-light rounded focus:outline-none"]),
                      style: {"text-shadow":"1px 1px 0 rgba(255,255,255,0.6)"}
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("span", { class: "hidden md:block" }, "Become a Founding Trader", -1),
                      _createElementVNode("span", { class: "block md:hidden" }, "Register", -1)
                    ]), 2)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("button", {
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearInvite && _ctx.clearInvite(...args))),
                      type: "button",
                      class: "inline-flex rounded-md p-1 px-3 focus:outline-none"
                    }, " X ")
                  ])
                ]))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("nav", {
      class: _normalizeClass([[_ctx.isDarkTheme ? 'border-black' : ''], "border-b relative z-[1]"]),
      style: _normalizeStyle([_ctx.isDarkTheme ? 'box-shadow: 0 1px 0 rgba(255,255,255,0.2)' : ''])
    }, [
      _createVNode(_component_Disclosure, {
        as: "header",
        class: _normalizeClass([[_ctx.isDarkTheme ? 'bg-[#1f2b3a]' : 'bg-white'], "shadow"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_router_link, {
                  to: "/",
                  class: "absolute block h-auto w-28 md:w-40 top-0 left-0 px-[8px] md:px-[12px] pt-[2px] md:pt-[8px] pb-[10px] md:pb-12px text-center border border-black border-t-0 rounded-sm rounded-t-none shadow",
                  style: {"background-color":"#141618"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_InlineSvg, {
                      class: "LOGO relative inline-block opacity-80 w-full",
                      src: require(`@/assets/${_ctx.appId}/logo-full.svg`),
                      alt: "Ammo Exchange"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      (_ctx.memberId)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            _createElementVNode("a", {
                              href: `//${_ctx.platformDomain}/`,
                              class: _normalizeClass([[_ctx.isDarkTheme ? 'text-gray-300' : 'text-gray-600'], "px-3 py-2 text-sm font-light cursor-pointer border border-slate-600 rounded"])
                            }, [
                              _cache[8] || (_cache[8] = _createTextVNode("Open Your Trading Dashboard ")),
                              _createVNode(_component_ChevronDoubleRightIcon, { class: "relative -top-[1px] h-4 inline" })
                            ], 10, _hoisted_24)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createVNode(_component_router_link, {
                              to: "/why-ammo-now",
                              class: _normalizeClass([[_ctx.isDarkTheme ? 'text-gray-300' : 'text-gray-600'], "sm:px-3 sm:py-2 sm:text-sm text-[14px] font-light cursor-pointer whitespace-nowrap py-[3px] px-[2px]"])
                            }, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode("Why Ammo?")
                              ])),
                              _: 1
                            }, 8, ["class"]),
                            _createElementVNode("a", {
                              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openLogin && _ctx.openLogin(...args))),
                              class: _normalizeClass([[_ctx.isDarkTheme ? 'text-gray-300' : 'text-gray-600'], "sm:px-3 sm:py-2 sm:text-sm text-[14px] font-light cursor-pointer whitespace-nowrap py-[3px] px-[2px]"])
                            }, "Login", 2),
                            _createElementVNode("a", {
                              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
                              class: _normalizeClass([[_ctx.isDarkTheme ? 'text-gray-900' : 'text-gray-600'], "sm:px-3 sm:py-2 sm:text-sm text-[14px] font-light cursor-pointer border border-black bg-blue-100 rounded whitespace-nowrap py-[3px] px-[6px]"]),
                              style: {"text-shadow":"1px 1px 0 rgba(255,255,255,0.5)"}
                            }, _cache[10] || (_cache[10] = [
                              _createElementVNode("span", { class: "" }, "Create Account", -1)
                            ]), 2)
                          ]))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["class"])
    ], 6)
  ]))
}