import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/dog.svg'
import _imports_1 from '@/assets/images/boxes-max.svg'
import _imports_2 from '@/assets/images/boxes-end.svg'
import _imports_3 from '@/assets/images/forklift.svg'
import _imports_4 from '@/assets/images/platform.png'
import _imports_5 from '@/assets/images/inspect.png'
import _imports_6 from '@/assets/images/inspect-belt.svg'
import _imports_7 from '@/assets/images/armory-lines.svg'
import _imports_8 from '@/assets/images/armory-bg.svg'
import _imports_9 from '@/assets/images/armory.svg'
import _imports_10 from '@/assets/images/shipping.png'
import _imports_11 from '@/assets/images/sun.svg'
import _imports_12 from '@/assets/images/hunting-cabin.svg'
import _imports_13 from '@/assets/images/transpricing.png'
import _imports_14 from '@/assets/images/buy:sell.png'
import _imports_15 from '@/assets/images/FreeLimit.png'
import _imports_16 from '@/assets/images/histinsights.png'


const _hoisted_1 = { class: "page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center" }
const _hoisted_2 = { class: "page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center" }
const _hoisted_3 = { class: "page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center" }
const _hoisted_4 = { class: "page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_BasicLayout = _resolveComponent("BasicLayout")!
  const _component_OverlayHowItWorksVideo = _resolveComponent("OverlayHowItWorksVideo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BasicLayout, { navIsDarkTheme: true }, {
      default: _withCtx(() => [
        _createVNode(_component_Header),
        _createElementVNode("div", null, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "relative pt-12 pb-14 w-5/5 mx-auto" }, [
            _createElementVNode("h1", { class: "font-ammocorps text-center text-xl md:text-3xl" }, "How Investing In Ammo Works"),
            _createElementVNode("ul", { class: "grid grid-flow-row md:grid-flow-col divide-x text-center md:divide-x divide-gray-200 md:mt-16" }, [
              _createElementVNode("li", { class: "md:-mt-10 pt-5 pb-5" }, [
                _createElementVNode("div", { class: "font-ammocorps text-4xl opacity-70" }, "1"),
                _createElementVNode("div", { class: "font-bold pb-2 md:text-xl" }, [
                  _createTextVNode("Create & Fund"),
                  _createElementVNode("br"),
                  _createTextVNode("Your Account")
                ]),
                _createElementVNode("p", { class: "px-5 md:px-10 font-extralight text-tiny" }, "It's as simple as E*Trade or Robinhood. Use your credit card or a bank transfer.")
              ]),
              _createElementVNode("li", { class: "md:-mt-10 pt-5 pb-5" }, [
                _createElementVNode("div", { class: "font-ammocorps text-4xl opacity-70" }, "2"),
                _createElementVNode("div", { class: "font-bold pb-2 md:text-xl" }, [
                  _createTextVNode("Buy At Real"),
                  _createElementVNode("br"),
                  _createTextVNode("Market Prices")
                ]),
                _createElementVNode("p", { class: "px-5 md:px-10 font-extralight text-tiny" }, "We charge zero buying commissions. The prices you see are raw market prices from sellers.")
              ]),
              _createElementVNode("li", { class: "md:-mt-10 pt-5 pb-5" }, [
                _createElementVNode("div", { class: "font-ammocorps text-4xl opacity-70" }, "3"),
                _createElementVNode("div", { class: "font-bold pb-2 md:text-xl" }, [
                  _createTextVNode("Store Free"),
                  _createElementVNode("br"),
                  _createTextVNode("In Our Armory")
                ]),
                _createElementVNode("p", { class: "px-5 md:px-10 font-extralight text-tiny" }, "Every trade gives you free storage. Keep it and trade later or ship it to yourself whenever.")
              ]),
              _createElementVNode("li", { class: "md:-mt-10 pt-5 pb-5" }, [
                _createElementVNode("div", { class: "font-ammocorps text-4xl opacity-70" }, "4"),
                _createElementVNode("div", { class: "font-bold pb-2 md:text-xl" }, [
                  _createTextVNode("Sell When the"),
                  _createElementVNode("br"),
                  _createTextVNode("Price Is Right")
                ]),
                _createElementVNode("p", { class: "px-5 md:px-10 font-extralight text-tiny" }, "Buy low, sell high. Ammo held in our armories can be resold instantly to other buyers.")
              ])
            ]),
            _createElementVNode("div", { class: "border-bottom-inner absolute bottom-0 left-0 w-full bg-white" })
          ], -1)),
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "page-section relative overflow-hidden bg-white" }, [
            _createElementVNode("div", { class: "section-top p-8 md:p-24 pb-0" }, [
              _createElementVNode("div", { class: "w-12/12 lg:w-8/12" }, [
                _createElementVNode("h1", { class: "font-ammocorps text-4xl mt-16 md:mt-0 mb-6" }, [
                  _createElementVNode("span", { class: "md:block" }, "YOU'LL ALWAYS FIND AMMO"),
                  _createTextVNode(" IN-STOCK AND AT GREAT PRICES")
                ]),
                _createElementVNode("p", { class: "text-lg font-light" }, "USAE connects the world's largest suppliers directly to consumers. There are no middlemen, no empty shelves, and no pre-set prices. Our real-time trading engine gives everyone equal opportunity to buy and sell as free-market prices fluctuate to balance supply and demand.")
              ])
            ]),
            _createElementVNode("div", { class: "section-bottom flex flex-row mt-5" }, [
              _createElementVNode("div", { class: "flex-1 flex items-end invisible md:visible relative" }, [
                _createElementVNode("div", { class: "callout-text absolute top-[5%] left-8 md:left-24 w-10/12 bg-white flex flex-row" }, [
                  _createElementVNode("div", { class: "relative -top-3 font-light pr-2 text-[#214489]" }, [
                    _createTextVNode("Prices move in real time"),
                    _createElementVNode("br"),
                    _createTextVNode("as trades are executed")
                  ]),
                  _createElementVNode("div", {
                    class: "line flex-1 relative",
                    style: {"top":"2px","height":"2px","background":"#9EB5E2"}
                  })
                ]),
                _createElementVNode("div", { class: "flex-1 w-full relative h-[60%]" }, [
                  _createElementVNode("div", { class: "absolute h-full w-[110%] -left-[10%] drop-shadow-lg flex flex-row" }, [
                    _createElementVNode("div", { class: "pallet-overflow flex-1 relative h-full" }, [
                      _createElementVNode("img", {
                        class: "absolute bottom-[105%] left-14 lg:left-20 opacity-60",
                        src: _imports_0
                      }),
                      _createElementVNode("div", { class: "absolute bottom-[5%] right-0 h-full w-full" }, [
                        _createElementVNode("div", {
                          class: "flex-1 relative",
                          style: {"background":"url(/images/boxes-max.svg) repeat-x top right","background-size":"auto 100%","right":"-1.5px","height":"calc(100% + 0.3px)"}
                        })
                      ]),
                      _createElementVNode("div", {
                        class: "pallet w-full h-[7%] absolute -bottom-[1%]",
                        style: {"background":"url(/images/pallet-segment.svg) repeat-x top right","background-size":"auto 100%"}
                      })
                    ]),
                    _createElementVNode("div", { class: "pallet-partial relative h-full text-right w-[58%]" }, [
                      _createElementVNode("div", { class: "absolute bottom-[5%] right-0 h-full w-full" }, [
                        _createElementVNode("img", {
                          class: "inline align-top max-h-full border-l border-gray-400",
                          src: _imports_1
                        }),
                        _createElementVNode("img", {
                          class: "inline align-top max-h-full",
                          src: _imports_1
                        }),
                        _createElementVNode("img", {
                          class: "inline align-top max-h-full",
                          src: _imports_1
                        }),
                        _createElementVNode("img", {
                          class: "inline align-top max-h-full",
                          src: _imports_1
                        }),
                        _createElementVNode("img", {
                          class: "inline align-top max-h-full -ml-[1px]",
                          src: _imports_2
                        })
                      ]),
                      _createElementVNode("div", {
                        class: "pallet w-full h-[7%] absolute -bottom-[1%] left-[7%]",
                        style: {"background":"url(/images/pallet-segment.svg) repeat-x top right","background-size":"auto 100%"}
                      })
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "w-5/12 relative -right-12 drop-shadow-lg" }, [
                  _createElementVNode("img", {
                    class: "",
                    src: _imports_3
                  })
                ])
              ]),
              _createElementVNode("div", { class: "relative w-10/12 md:w-5/12 text-right" }, [
                _createElementVNode("div", { class: "callout-lines hidden md:block absolute z-10 top-[5%] left-0 w-full h-full" }, [
                  _createElementVNode("div", {
                    class: "line-vertical absolute top-0 left-[33%]",
                    style: {"width":"6px","height":"27%","background":"#9EB5E2","border":"2px solid white","border-bottom":"none"}
                  }),
                  _createElementVNode("div", {
                    class: "line-horizontal absolute top-0 -left-[33%] z-[2]",
                    style: {"width":"calc(66% + 2px)","height":"6px","background":"#9EB5E2","border":"2px solid white","border-right":"none","border-left":"none"}
                  })
                ]),
                _createElementVNode("img", {
                  class: "shadow-xl relative top-10 inline-block border border-[#AFB3B4] rounded",
                  style: {"transform":"rotate(-5deg)","box-shadow":"0 0 10px rgba(0,0,0,.2)"},
                  src: _imports_4
                })
              ])
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_1, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "border-top-inner absolute top-0 left-0 w-full bg-white" }, null, -1)),
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
              class: "px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded"
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("span", null, "Join the Platform", -1)
            ]))
          ]),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "page-section relative overflow-hidden" }, [
            _createElementVNode("div", { class: "border-top-inner absolute top-0 left-0 w-full bg-white" }),
            _createElementVNode("div", { class: "section-top p-8 md:p-24 md:pb-0 mb-0 md:mb-24" }, [
              _createElementVNode("div", { class: "w-12/12 flex flex-col items-end" }, [
                _createElementVNode("h1", { class: "font-ammocorps text-4xl text-right mt-16 md:mt-0 mb-6 lg:w-8/12" }, [
                  _createElementVNode("span", { class: "md:block" }, "EVERY TRANSACTION IS INSPECTED,"),
                  _createTextVNode(" CERTIFIED, AND INSURED")
                ]),
                _createElementVNode("p", { class: "text-lg font-light text-right lg:w-8/12" }, "All trades on the USAE are backed by real ammo. No exceptions. Sellers are required to ship their ammo to our secured armories where experts inspect and certify a broad range of attributes including brand, features, and quality. Only then does trading begin.")
              ])
            ]),
            _createElementVNode("div", { class: "section-bottom flex flex-row mt-5 relative" }, [
              _createElementVNode("div", { class: "callout-text absolute -top-[1%] right-8 md:right-24 w-6/12 bg-white flex flex-row invisible md:visible" }, [
                _createElementVNode("div", {
                  class: "line flex-1 relative",
                  style: {"top":"2px","height":"2px","background":"#9EB5E2"}
                }),
                _createElementVNode("div", { class: "relative -top-3 font-light pl-2 text-[#214489] text-right" }, [
                  _createTextVNode("We guarantee every "),
                  _createElementVNode("br"),
                  _createTextVNode("round in your portfolio")
                ])
              ]),
              _createElementVNode("div", { class: "relative w-10/12 md:w-5/12 text-left" }, [
                _createElementVNode("div", { class: "callout-lines hidden md:block absolute z-20 -top-[1%] left-0 w-full h-full" }, [
                  _createElementVNode("div", {
                    class: "line-vertical absolute top-0 right-[14%]",
                    style: {"width":"6px","height":"13%","background":"#9EB5E2","border":"2px solid white","border-bottom":"none"}
                  }),
                  _createElementVNode("div", {
                    class: "line-horizontal absolute top-0 -right-[14%] z-[2]",
                    style: {"width":"calc(28% + 2px)","height":"6px","background":"#9EB5E2","border":"2px solid white","border-right":"none","border-left":"none"}
                  })
                ]),
                _createElementVNode("img", {
                  class: "shadow-xl relative top-10 inline-block border border-[#AFB3B4] rounded z-10",
                  style: {"transform":"rotate(5deg)","box-shadow":"0 0 10px rgba(0,0,0,.2)"},
                  src: _imports_5
                })
              ]),
              _createElementVNode("div", { class: "flex-1 invisible md:visible relative" }, [
                _createElementVNode("div", { class: "absolute w-[120%] right-[5%] bottom-0" }, [
                  _createElementVNode("img", {
                    class: "drop-shadow-lg",
                    src: _imports_6
                  })
                ])
              ])
            ]),
            _createElementVNode("div", { class: "border-bottom-inner absolute bottom-0 left-0 w-full bg-white" })
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "border-top-inner absolute top-0 left-0 w-full bg-white" }, null, -1)),
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
              class: "px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded"
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("span", null, "Join the Platform", -1)
            ]))
          ]),
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "page-section relative overflow-hidden bg-white" }, [
            _createElementVNode("div", { class: "section-top p-8 md:p-24 md:pb-0 mb-10 md:mb-16" }, [
              _createElementVNode("div", { class: "w-12/12 lg:w-8/12" }, [
                _createElementVNode("h1", { class: "font-ammocorps text-4xl mt-16 md:mt-0 mb-6" }, [
                  _createElementVNode("span", { class: "md:block" }, "OUR REAL-TIME STORAGE PLATFORM "),
                  _createElementVNode("span", { class: "md:block" }, "LETS YOU TAKE INSTANT ADVANTAGE "),
                  _createTextVNode("OF PRICE SWINGS")
                ]),
                _createElementVNode("p", { class: "text-lg font-light" }, "Inventory on the USAE is held in advanced high-tech armories, which are digitally connected into our trading platforms. This allows you to buy and sell at the click of a button — no packing, shipping, or handling of pallets. Legal ownership of your ammo is transfered instantly.")
              ])
            ]),
            _createElementVNode("div", { class: "relative section-bottom h-36 sm:h-64 lg:h-[25rem] ml-8 md:ml-24 border-l border-gray-400" }, [
              _createElementVNode("div", { class: "y-axis absolute -left-[14px] h-full" }, [
                _createElementVNode("div", { class: "x-item top-[0%]" }, "$5.00"),
                _createElementVNode("div", { class: "x-item top-[19%]" }, "$4.00"),
                _createElementVNode("div", { class: "x-item top-[38%]" }, "$3.00"),
                _createElementVNode("div", { class: "x-item top-[57%]" }, "$2.00"),
                _createElementVNode("div", { class: "x-item top-[76%]" }, "$1.00"),
                _createElementVNode("div", { class: "x-item top-[95%]" }, "$0.00")
              ]),
              _createElementVNode("div", { class: "returns left-[36%] top-[23%] text-red-500" }, [
                _createTextVNode("-"),
                _createElementVNode("span", null, "9"),
                _createTextVNode("%")
              ]),
              _createElementVNode("div", { class: "returns left-[51%] top-[8%] text-green-500" }, [
                _createTextVNode("+"),
                _createElementVNode("span", null, "42"),
                _createTextVNode("%")
              ]),
              _createElementVNode("div", { class: "returns left-[83%] top-[13%] text-red-500" }, [
                _createTextVNode("-"),
                _createElementVNode("span", null, "13"),
                _createTextVNode("%")
              ]),
              _createElementVNode("div", { class: "returns left-[96.5%] -top-[7%] text-green-500" }, [
                _createTextVNode("+"),
                _createElementVNode("span", null, "68"),
                _createTextVNode("%")
              ]),
              _createElementVNode("div", { class: "absolute left-0 w-full h-full z-50" }, [
                _createElementVNode("img", {
                  class: "w-full h-full z-40",
                  style: {"transform":"rotate(0deg)"},
                  src: _imports_7
                })
              ]),
              _createElementVNode("div", {
                class: "absolute right-0 w-10/12 md:w-7/12 lg:w-6/12 z-50",
                style: {"bottom":"-5px"}
              }, [
                _createElementVNode("img", {
                  class: "absolute w-[104%] -left-[2%] bottom-0 z-20 max-w-none",
                  style: {"transform":"rotate(0deg)"},
                  src: _imports_8
                }),
                _createElementVNode("img", {
                  class: "relative w-12/12 z-40",
                  style: {"transform":"rotate(0deg)"},
                  src: _imports_9
                })
              ])
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "border-top-inner absolute top-0 left-0 w-full bg-white" }, null, -1)),
            _createElementVNode("a", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
              class: "px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded"
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("span", null, "Join the Platform", -1)
            ]))
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "page-section relative overflow-hidden" }, [
            _createElementVNode("div", { class: "border-top-inner absolute top-0 left-0 w-full bg-white" }),
            _createElementVNode("div", { class: "section-top p-8 md:p-24 md:pb-0 mb-0 md:mb-20" }, [
              _createElementVNode("div", { class: "w-12/12 flex flex-col items-end" }, [
                _createElementVNode("h1", { class: "font-ammocorps text-4xl text-right mt-16 md:mt-0 mb-6 lg:w-8/12" }, [
                  _createElementVNode("span", { class: "md:block" }, "THE ONLY INVESTMENT PORTFOLIO "),
                  _createTextVNode("YOU CAN SAFELY SHOOT")
                ]),
                _createElementVNode("p", { class: "text-lg font-light text-right lg:w-8/12" }, "It’s your ammo. Whether you need boxes for a hunting trip or just want to spend an hour at the shooting range, your ammo is always ready. Open your portfolio and click “Ship to Me”. Our team will ship your boxes within 48 hours. It’s like having your own personal armory; actually, that’s exactly what it is.")
              ])
            ]),
            _createElementVNode("div", { class: "section-bottom flex flex-row mt-0 relative" }, [
              _createElementVNode("div", { class: "callout-text absolute top-0 right-8 md:right-[25%] w-4/12 flex flex-row invisible md:visible" }, [
                _createElementVNode("div", {
                  class: "line flex-1 relative",
                  style: {"top":"2px","height":"2px","background":"#9EB5E2"}
                }),
                _createElementVNode("div", { class: "relative -top-3 font-light pl-2 text-[#214489] text-right" }, [
                  _createTextVNode("Ship it on demand"),
                  _createElementVNode("br"),
                  _createTextVNode(" whenever you want")
                ])
              ]),
              _createElementVNode("div", { class: "relative w-10/12 md:w-5/12 text-left" }, [
                _createElementVNode("div", { class: "callout-lines hidden md:block absolute z-20 -top-0 left-0 w-full h-full" }, [
                  _createElementVNode("div", {
                    class: "line-vertical absolute top-0 right-[17%]",
                    style: {"width":"6px","height":"60%","background":"#9EB5E2","border":"2px solid white","border-bottom":"none"}
                  }),
                  _createElementVNode("div", {
                    class: "line-horizontal absolute top-0 -right-[17%] z-[2]",
                    style: {"width":"calc(34% + 2px)","height":"6px","background":"#9EB5E2","border":"2px solid white","border-right":"none","border-left":"none"}
                  })
                ]),
                _createElementVNode("img", {
                  class: "shadow-xl relative -bottom-36 -mt-24 inline-block border border-[#AFB3B4] rounded z-10",
                  style: {"transform":"rotate(5deg)","box-shadow":"0 0 10px rgba(0,0,0,.2)"},
                  src: _imports_10
                })
              ]),
              _createElementVNode("div", { class: "flex-1 invisible md:visible relative" }, [
                _createElementVNode("div", { class: "absolute top-0 right-[16%]" }, [
                  _createElementVNode("img", {
                    class: "drop-shadow-lg h-24",
                    src: _imports_11
                  })
                ]),
                _createElementVNode("div", { class: "absolute w-[105%] right-0 bottom-0" }, [
                  _createElementVNode("img", {
                    class: "drop-shadow-lg",
                    src: _imports_12
                  })
                ])
              ])
            ]),
            _createElementVNode("div", { class: "border-bottom-inner absolute bottom-0 left-0 w-full bg-white" })
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "border-top-inner absolute top-0 left-0 w-full bg-white" }, null, -1)),
            _createElementVNode("a", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openRegister && _ctx.openRegister(...args))),
              class: "px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded"
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("span", null, "Join the Platform", -1)
            ]))
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "bg-white w-full flex flex-col items-center pt-12 md:pt-24 pb-24" }, [
            _createElementVNode("h1", { class: "pt-4 mb-12 text-4xl font-ammocorps text-center w-10/12 md:w-6/12" }, [
              _createElementVNode("span", { class: "inline md:block" }, "POWERFUL TRADING TOOLS "),
              _createTextVNode("DELIVER SIMPLICITY AND SPEED")
            ]),
            _createElementVNode("div", { class: "flex flex-col md:flex-row w-full lg:w-9/12" }, [
              _createElementVNode("div", { class: "w-full md:w-6/12 py-8 px-12 text-center md:text-left" }, [
                _createElementVNode("h1", { class: "text-xl font-ammocorps" }, "transparent pricing"),
                _createElementVNode("p", null, "The USAE shows no favoritism among its traders. Everyone sees the same price at the same time for every trade."),
                _createElementVNode("img", {
                  src: _imports_13,
                  class: "inline h-40 mt-6"
                })
              ]),
              _createElementVNode("div", { class: "w-full md:w-6/12 py-8 px-12 text-center md:text-left" }, [
                _createElementVNode("h1", { class: "pt-4 text-xl font-ammocorps" }, "buy/sell order books"),
                _createElementVNode("p", null, "Real-time order books give you full transparency into where other traders are setting buy and sell prices."),
                _createElementVNode("img", {
                  src: _imports_14,
                  class: "inline h-40 mt-6"
                })
              ])
            ]),
            _createElementVNode("div", { class: "flex flex-col md:flex-row w-full lg:w-9/12" }, [
              _createElementVNode("div", { class: "w-full md:w-6/12 py-8 px-12 text-center md:text-left" }, [
                _createElementVNode("h1", { class: "text-xl font-ammocorps" }, "historical insights"),
                _createElementVNode("p", null, "Historical price trends can indicate the future direction of a market. The USAE provides up to 90 days of free pricing data for every product."),
                _createElementVNode("img", {
                  src: _imports_15,
                  class: "inline h-40 mt-6"
                })
              ]),
              _createElementVNode("div", { class: "w-full md:w-6/12 py-8 px-12 text-center md:text-left" }, [
                _createElementVNode("h1", { class: "text-xl font-ammocorps" }, "free limit orders"),
                _createElementVNode("p", null, "Whether you’re buying or selling, USAE’s limit orders put you in the driver’s seat. Set your perferred price and your trade executes only when the price matches."),
                _createElementVNode("img", {
                  src: _imports_16,
                  class: "inline h-40 mt-6"
                })
              ])
            ])
          ], -1))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_OverlayHowItWorksVideo)
  ], 64))
}