<template>
  <footer
    class="bg-[#3d4957] py-6 px-12 text-left md:text-center grid md:grid-cols-3 gap-4 text-[#c4c8cc]"
  >
    <p>1504 Alcovy Trestle Rd, Social Circle, GA 30025</p>
    <p>
      Ryan Millsap
      <a class="hover:underline" href="mailto:ryan@ammoexchange.com"
        >(ryan@ammoexchange.com)</a
      >, Garrett Gravesen
      <a class="hover:underline" href="mailto:garrett@ammoexchange.com"
        >(garrett@ammoexchange.com)</a
      >
    </p>
    <p>
      <router-link class="hover:underline" to="/legal"
        >User Agreement</router-link
      >
    </p>
  </footer>
</template>

<script lang="ts">
import * as Vue from "vue"
import ContentWrapper from "@/layouts/ContentWrapper.vue"
import { config } from "@/main"

export default Vue.defineComponent({
  components: {
    ContentWrapper,
  },
  setup() {
    return {
      legalOwner: config.legalOwner,
    }
  },
})
</script>
